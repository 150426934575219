import { Authz } from "@practice/authz";
import { ActorType, ExtendedAccount } from "@practice/sdk";

import { AppointmentType } from "@lib/data/schemas/appointment";

export const filterRecurringDeclinedAndArchivedContactAppointments = (
  appointment: AppointmentType
): boolean =>
  !(
    (["cancelled", "declined"].includes(appointment.status) &&
      appointment.recurring === true) ||
    appointment.contact?.status === "archived" ||
    appointment.repeat
  );

export const filterDeletedAppointments = (
  appointment: AppointmentType
): boolean => appointment.status !== "deleted";

const filterUnlinkedAppointments = (noteId: string) => {
  return function (appointment: AppointmentType) {
    return (
      !appointment?.noteIds?.includes(noteId) &&
      !appointment.groupId &&
      !appointment.isEvent &&
      !["declined", "deleted", "cancelled"].includes(appointment.status)
    );
  };
};

const sortAppointmentsByStartDateDesc = (
  leftAppointment: AppointmentType,
  rightAppointment: AppointmentType
) => (leftAppointment.start < rightAppointment.start ? 1 : -1);

export const unlinkedAppointmentsForNote = (
  noteId: string,
  appointments: AppointmentType[]
): AppointmentType[] => {
  return appointments
    .filter(filterUnlinkedAppointments(noteId))
    .sort(sortAppointmentsByStartDateDesc);
};

export const findZoomCommunication = (item: { value: string }) =>
  item.value === "zoom-call";

export const getLocationData = (event: any, existing: any) => {
  if (event.location && event.location.includes("zoom.us")) {
    return {
      location: event.location,
      locationType: "zoom-call",
    };
  } else if (event.location && event.location.includes("meet.google")) {
    return {
      location: event.location,
      locationType: "google-call",
    };
  } else if (
    event.location &&
    (event.location.includes("http") || event.location.includes("https"))
  ) {
    return {
      location: event.location,
      locationType: "video-call",
    };
  } else if (event.location && !event.locationType) {
    return {
      location: event.location,
      locationType: "in-person",
    };
  } else if (!event.location && event.hangoutLink) {
    return {
      location: event.hangoutLink,
      locationType: "google-call",
    };
  } else if (!event.location) {
    return {
      location: "",
      locationType: "in-person",
    };
  }
  return existing;
};

/**
 * Inject missing data into appointments
 * Legacy appointments may not have certain fields such as locationMemberId
 * @param appointment the appointment to fix
 * @param oid the organization this appointment belongs to
 * @returns an appointment with all missing data filled in
 */
export const fixAppointment = (
  appointment: AppointmentType,
  oid: string | undefined,
  getCalendarOwner: (
    calendarId?: string,
    accountId?: string
  ) => ExtendedAccount | undefined
): AppointmentType & { organizer?: ExtendedAccount; memberIds: string[] } => {
  const { locationType, locationMemberId, ...rest } = appointment;

  const fallbackLocationMemberId = locationMemberId || oid;

  const gcalEvent = appointment?.gcal?.event;

  const organizer = gcalEvent?.organizer?.email
    ? getCalendarOwner(gcalEvent?.organizer?.email) ??
      getCalendarOwner(
        gcalEvent?.creator?.email ||
          appointment?.customEventInformation?.organizer
      )
    : undefined;

  const attendees: { id: string }[] =
    gcalEvent?.attendees
      ?.map((attendee) => getCalendarOwner(attendee.email))
      ?.filter(Boolean) || [];

  const memberIds = new Set([
    ...(organizer ? [organizer.id] : []),
    ...attendees?.map((attendee) => attendee?.id),
  ]);

  return {
    locationType: locationType as AppointmentType["locationType"],
    locationMemberId:
      locationType === "zoom-call" ? fallbackLocationMemberId : undefined,
    organizer,
    memberIds: [...memberIds],
    ...rest,
    start:
      typeof appointment.start === "string"
        ? new Date(appointment.start)
        : appointment.start,
    end:
      typeof appointment.end === "string"
        ? new Date(appointment.end)
        : appointment.end,
  };
};

/**
 * Return the data to be used with the activity log based on the origin
 * */
export const getCurrentActorData = async (
  clientId: string,
  origin: "coach" | "client"
) => {
  const accountOwnerMemberId = await Authz.get.user.that.owns.contact(clientId);
  const actorData = {
    coach: { type: ActorType.Account, id: accountOwnerMemberId },
    client: { type: ActorType.Client, id: clientId },
  }[origin];
  return actorData;
};

export const getAppointmentDuration = (appointment: AppointmentType) => {
  const start = new Date(appointment.start);
  const end = new Date(appointment.end);
  return Math.abs(end.getTime() - start.getTime()) / 1000 / 60;
};
